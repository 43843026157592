import { Injectable } from '@angular/core';

import { STS } from '@aws-sdk/client-sts';

import { AwsCredentialIdentity,AwsCredentialIdentityProvider } from '@smithy/types';

//Note that this service is responsible for accessing ALL items in LocalStorage
//not just the actual credentials

@Injectable({
  providedIn: 'root'
})
export class AwsCredentialsService {

	private credentials!: AwsCredentialIdentity; 

	public async hasCredentials(): Promise<boolean> {

		if (this.credentials) {
			return true;
		}

		const encodedCredentials = localStorage.getItem('awscredentials');
		if (encodedCredentials) {
			const credentials = JSON.parse(encodedCredentials);

			credentials.expiration = new Date(credentials.expiration);

			if (await this.checkCredentials(credentials)) {
				this.credentials = credentials;
				return true;
			}
		}

		return false;
	}

	public logout() {

		localStorage.removeItem('awscredentials');

		//These properties are only in the localStorage for local development
		//and are placed their by the local-saml component
		//For actual DEV,TEST,PROD deployments, the tablename and clusters
		//are returned in the lambda@edge response for login
		localStorage.removeItem('tablename');
		localStorage.removeItem('clusters');

	}

	public getTableName(): string {

		const table = localStorage.getItem('tablename');
		
		if (table) {
			return table;
		}

		throw 'Could not retrieve table from localStorage';

	}


	public getClusters(): string[] {

		const raw = localStorage.getItem('clusters');
		if (raw) {
			return <string[]> JSON.parse(raw);
		}
		return [];
	}

	public getCredentials(): AwsCredentialIdentity {
		return this.credentials;
	}

	public async setCredentials(credentials: AwsCredentialIdentity): Promise<boolean> {

		if (await this.checkCredentials(credentials)) {
			this.credentials = credentials;
			localStorage.setItem('awscredentials', JSON.stringify(credentials));

			return true;

		}

		return false;
		
	}

	private async checkCredentials(credentials: AwsCredentialIdentity):Promise<boolean> {

		if (credentials.accessKeyId == 'localhost') {
			return true;
		}

		const client = new STS({credentials:credentials, region:'us-east-1'});
		
		try {

			const result = await client.getCallerIdentity({});

			return true;
		} catch(err) {
			console.log(err);
		}

		return false;

	}

}
